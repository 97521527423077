<template>
  <div>
    <v-btn small @click="showCharge = true" color="gray"
      >Extra Charge
      <v-icon right small color="green">mdi-currency-usd</v-icon></v-btn
    >

    <v-dialog v-model="showCharge" max-width="400">
      <v-card>
        <v-card-title>
          Apply Exra Charge.
        </v-card-title>
        <v-card-text class="body-2"
          >Charge take 5-10 days to appear on a customer's
          statement.</v-card-text
        >
        <v-card-text>
          <v-text-field
            label="
            Extra Charge Amount 
            "
            type="number"
            v-model="amount"
            :rules="[refundRules]"
            class="mb-4"
          ></v-text-field>
          Max Charge
          {{ maxCharge | currency }}
          <v-text-field
            maxlength="250"
            label="Reason"
            v-model="message"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.stop="showCharge = false"
            >Cancel
          </v-btn>
          <v-btn
            color="success"
            @click.stop="exrtraChargeOrder"
            :disabled="typeof refundRules === 'string' || !message"
          >
            Charge
            <v-icon dark right>attach_money</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    maxCharge: {
      type: Number,
      default: 0.0
    },
    myOrder: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin'
    }),
    refundRules() {
      if (this.amount) {
        if (this.amount <= 0) {
          return 'Amount must be greater than 0'
        }
        if (this.amount > this.maxCharge) {
          return 'Amount cannot be greater than the max'
        }
      }
      if (!this.amount) {
        return 'Amount cannot be empty'
      }
      return true
    }
  },
  watch: {
    openRefund() {
      this.showCharge = this.showCharge
    }
  },
  data() {
    return {
      showCharge: false,
      message: null,
      amount: null
    }
  },
  methods: {
    exrtraChargeOrder() {
      const charge = {
        uid: this.myOrder.sellerUserId,
        orderId: this.myOrder.orderId,
        amount: parseFloat(this.amount),
        message: this.message,
        buyer: this.myOrder.buyer
      }

      this.$store.dispatch('submitExtraCharge', charge).then(this.reset())
    },

    reset() {
      this.showCharge = false
      this.amount = null
      this.message = null
      this.amountType = '$'
      this.type = 'money'
    }
  }
}
</script>

<style lang="scss" scoped></style>
